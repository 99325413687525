import { memo } from "react";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, useFortuneWheelCountdown } from "@shared/lib";

import { calcPointX, calcPointY, WHEEL_RADIUS as R } from "../lib";

import type { PropsWithChildren } from "react";

export const Timer = memo(function Timer() {
  const { data } = FortuneWheel.useWheelState();

  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);

  return (
    <text
      className="font-oswald fill-white text-2xl font-extrabold [text-shadow:0_0_24px_#000]"
      alignmentBaseline="middle"
      textAnchor="middle"
      x={R}
      y={R}
    >
      {formatTime(countdown)}
    </text>
  );
});

interface IWheelSectorProps {
  fill: string;
  path: string;
  isWinSector: boolean;
}

export function WheelSector({
  fill,
  path,
  isWinSector,
  ...restProps
}: IWheelSectorProps & React.SVGProps<SVGGElement>) {
  return (
    <g {...restProps}>
      <path fill={fill} d={path} />
      <path stroke="url(#linGrad003)" strokeWidth={3} d={path} />
      {isWinSector && (
        <path fill="#fff" fillOpacity="0" d={path}>
          <animate
            attributeName="fill-opacity"
            calcMode="discrete"
            values="0;0.25"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      )}
    </g>
  );
}

export function WheelSectorText({ x, y, children }: PropsWithChildren<{ x: number; y: number }>) {
  return (
    <text className="font-oswald tracking-[1rem] font-medium text-3xl fill-white" x={x} y={y}>
      {children}
    </text>
  );
}

export function Arrow() {
  return (
    <svg
      filter="url(#arrowShadowFilter)"
      xmlns="http://www.w3.org/2000/svg"
      x={R - 73 / 2}
      y={-26}
      width="73"
      height="110"
      viewBox="0 0 73 110"
    >
      <path fill="#fff3a6" d="M2.8 7 A110 110 0 0 1 70.8 7 L36.8 103 Z" />
      <path fill="url(#fw_arrow_linear1)" d="M36.8 0C24.6.05 12.43 2.1.8 6.13L36.8 107V0Z" />
      <path fill="url(#fw_arrow_linear2)" d="M36.8 0c12.18.05 24.35 2.1 36 6.13L36.8 107V0Z" />
      <path
        stroke="url(#fw_arrow_linear3)"
        strokeWidth="3.3"
        d="M2.8 7 A110 110 0 0 1 70.8 7 L36.8 103 Z"
      />
      <defs>
        <linearGradient id="fw_arrow_linear1">
          <stop stopColor="#cdcdcd" />
          <stop offset="1" stopColor="#b1b1b1" />
        </linearGradient>
        <linearGradient id="fw_arrow_linear2">
          <stop stopColor="#f1f1f1" />
          <stop offset="1" stopColor="#8d8d8d" />
        </linearGradient>
        <linearGradient id="fw_arrow_linear3" gradientTransform="rotate(45)">
          <stop stopColor="#8a8a8a" />
          <stop offset="1" stopColor="#f1f1f1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ArrowBase() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={R - 112 / 2 + 1}
      y={-20}
      width="112"
      height="47"
      viewBox="0 0 112 47"
    >
      <path fill="#aaa" d="M109.02 44.65H2.6L21.02 1.72H90.6l18.42 42.93Z" />
      <path fill="#000" fillOpacity=".4" d="M109.02 44.65H2.6L21.02 1.72H90.6l18.42 42.93Z" />
      <path
        stroke="url(#fw_arrow_base_linear2)"
        strokeWidth="3"
        d="M109.02 44.65H2.6L21.02 1.72H90.6l18.42 42.93Z"
      />
      <defs>
        <linearGradient id="fw_arrow_base_linear2" gradientTransform="rotate(90)">
          <stop stopColor="#b1b1b1" />
          <stop offset="1" stopColor="#aaa" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function OuterBorder() {
  return (
    <g>
      <circle fill="url(#sectorsShadowRadial)" cx="50%" cy="50%" r="44%" />
      <circle stroke="url(#outerRingLinGrad)" strokeWidth="15" cx="50%" cy="50%" r="49%" />
      <circle stroke="url(#paint72_linear_320_2707)" strokeWidth="35" cx="50%" cy="50%" r="46.5%" />
      <circle stroke="url(#paint73_linear_320_2707)" strokeWidth="6" cx="50%" cy="50%" r="43.9%" />
      <circle
        fill="none"
        stroke="url(#paint74_linear_320_2707)"
        strokeWidth="2"
        cx="50%"
        cy="50%"
        r="46.5%"
      />
    </g>
  );
}

export function Pins() {
  return (
    <g>
      {Array.from({ length: 20 }).map((_, idx, arr) => {
        const angle = (360 / arr.length) * idx;
        const x = calcPointX(R, R - 27, angle);
        const y = calcPointY(R, R - 27, angle);

        return (
          <use
            key={idx}
            href="#fortune-wheel-pin"
            x={x - 10}
            y={y - 10}
            transform={`rotate(${angle} ${x} ${y})`}
          />
        );
      })}
    </g>
  );
}
