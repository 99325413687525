import { memo } from "react";

import { Game } from "@entities/game/model";
import { range } from "@shared/lib";

import { useGroupedGameList } from "../lib";

import { GamesContainer } from "./index";

import type { PropsWithChildren } from "react";

interface IGameListsProps {
  className?: string;
}

export const GameLists = memo(function GameLists({
  className,
}: PropsWithChildren<IGameListsProps>) {
  const { isPending, isFetching } = Game.useList();

  const gameList = useGroupedGameList();

  return (
    <div className={className}>
      <div className="lg:gap-5 flex flex-col gap-3 *:lg:p-5 *:p-3 *:rounded-sm *:bg-athens-gray *:dark:bg-mine-shaft/50">
        {isPending || isFetching
          ? range(3)((idx) => <GamesContainer.Skeleton key={idx} />)
          : gameList.map(({ type, label, id, games }) => {
              if (!label) {
                return null;
              }

              return (
                <GamesContainer
                  key={id.toString()}
                  items={games}
                  expandable={gameList.length > 1}
                  expanded={gameList.length === 1}
                  categoryId={id}
                  type={type}
                  label={label}
                />
              );
            })}
      </div>
    </div>
  );
});
