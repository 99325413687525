import { useNavigate } from "@tanstack/react-router";
import { clsx } from "clsx";

import { LogoutButton } from "@features/player/logout-button";
import { useMobile } from "@shared/lib";
import { SVGIcon } from "@shared/ui";

import type { TProfileSectionItem } from "./profile-sections";
import type { TIconName } from "@shared/ui";
import type { TProfileSection } from "@widget/profile-modal/lib";

interface IProfileSectionControlsProps {
  currentSectionId: TProfileSection | undefined;
  items: TProfileSectionItem[];
}

export function ProfileSectionControls({ items, currentSectionId }: IProfileSectionControlsProps) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="lg:px-5 lg:py-4 lg:rounded-sm lg:flex lg:flex-col lg:max-h-full rounded-xs bg-athens-gray dark:bg-mine-shaft p-3">
      <ul className="lg:overflow-y-auto lg:tw-scrollbar lg:py-1 flex flex-col gap-2.5 mobile-only:max-h-max">
        {items.map(({ title, icon, id }, idx) => (
          <li key={idx}>
            <button
              className={clsx(
                "category-btn",
                id === currentSectionId && !isMobile && "category-btn_active",
              )}
              type="button"
              onClick={() =>
                navigate({
                  search: (prev) => ({ ...prev, profileSection: id }),
                })
              }
            >
              <SVGIcon className="lg:size-4 size-5" name={icon as TIconName} />
              <span className="truncate">{title}</span>
            </button>
          </li>
        ))}
      </ul>
      {!isMobile && <LogoutButton className="mt-5" />}
    </div>
  );
}
