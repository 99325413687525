export function chunkString(str: string, length: number) {
  return str.match(new RegExp(".{1," + length + "}", "g"));
}

export function debounce(fn: Function, delay = 400) {
  let timerId: ReturnType<typeof setTimeout>;

  return function (...args: unknown[]) {
    clearTimeout(timerId);

    timerId = setTimeout(() => fn(...args), delay);
  };
}

export function formatTime(time: number) {
  time = time / 1000;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export function range(n: number) {
  return (cb: (idx: number) => React.ReactNode) => Array.from({ length: n }, (_, idx) => cb(idx));
}
