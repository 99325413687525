import { useTranslate } from "@lobby/ocb-intl";
import { getRouteApi } from "@tanstack/react-router";
import { useMemo } from "react";

import { FortuneWheel } from "@entities/fortune-wheel";
import { Game } from "@entities/game/model";
import { CategoryButton } from "@features/category/category-button";
import { FortuneWheelButton } from "@features/fortune-wheel-button";
import { GameSearch } from "@features/game-search";
import { range, useAuth, useMobile } from "@shared/lib";

import { PrivateCategoryItems } from "./private-category-items";

const routeApi = getRouteApi("/");

export function CategoryList() {
  const { $t } = useTranslate();
  const { cid } = routeApi.useSearch();
  const isMobile = useMobile();

  const { isAuth } = useAuth();
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  const { data: categoryList, isPending, isSuccess: isQuerySuccess } = Game.useCategoriesList();
  const categories = useMemo(() => categoryList?.filter(({ tag }) => !tag), [categoryList]);

  const isSuccess = isQuerySuccess && categories;

  return (
    <div className="category-list">
      <div className="lg:flex-col p-3 lg:p-5 flex overflow-x-auto">
        <GameSearch className="mobile-only:hidden" />

        {isFortuneWheelAvailable && !isMobile && <FortuneWheelButton.Desktop />}

        <ul className="lg:flex-col lg:mt-5 flex gap-2.5">
          <li>
            <CategoryButton id="allGames" isActive={!cid}>
              {$t({ defaultMessage: "All Games" })}
            </CategoryButton>
          </li>

          {isAuth && <PrivateCategoryItems />}

          {isPending && range(8)((idx) => <CategoryButton.Skeleton key={idx} />)}
          {isSuccess &&
            categories.map(({ name, id }) => (
              <li key={id}>
                <CategoryButton isActive={cid === id} id={id}>
                  {name}
                </CategoryButton>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
