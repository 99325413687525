import { PropsWithChildren } from "react";

export function BackgroundLayer({ children }: PropsWithChildren) {
  return (
    <div className="bg-gradient-to-b from-vis-vis to-casablanca rounded-full p-[0.25em] size-[25em] absolute left-1/2 top-[48%] -translate-x-1/2 -translate-y-1/2">
      <div className="rounded-inherit bg-gradient-to-b from-[#c63cf6] via-[#6d26a5] via-[48%] to-[#4f0075] to-[48%] p-[0.625em] size-full">
        <div className="congrats-modal__core-bg rounded-inherit size-full">{children}</div>
      </div>
    </div>
  );
}

export function TakeButton({ onClick, children }: PropsWithChildren<{ onClick: VoidFunction }>) {
  return (
    <button
      className="bg-gradient-to-b from-vis-vis to-casablanca rounded-[6.25em] p-[0.25em] h-[4.375em] drop-shadow-[0_0.125em_0.0625em_#000000a6]"
      type="button"
      onClick={onClick}
    >
      <div className="lg:hover:from-[#56f63c] lg:hover:to-[#429834] bg-gradient-to-b from-[#429834] to-[#1a600f] rounded-inherit flex-center px-[1.875em] h-full">
        <span className="text-[2.25em] leading-none font-bold text-white uppercase">
          {children}
        </span>
      </div>
    </button>
  );
}
