import { useTranslate } from "@lobby/ocb-intl";
import { useSearch } from "@tanstack/react-router";
import { useCallback, useState } from "react";

import { Game } from "@entities/game/model";
import { ProviderButton } from "@features/provider-button";
import { debounce, useModal } from "@shared/lib";
import { InputSearch } from "@shared/ui/input-search";
import { Modal } from "@shared/ui/modal/modal";

import { PROVIDERS_MODAL_ID } from "../lib";

export function ProvidersModal() {
  const { isOpen, close } = useModal(PROVIDERS_MODAL_ID);

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <ProvidersComponent />
    </Modal>
  );
}

export function ProvidersComponent() {
  const { $t } = useTranslate();
  const { pid } = useSearch({ strict: false });
  const { close } = useModal(PROVIDERS_MODAL_ID);
  const [searchResult, setSearchResult] = useState<NonNullable<typeof sectionsListItems> | null>(
    null,
  );

  const { data } = Game.useProvidersList();

  const sectionsListItems = data?.result.data ?? [];

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (!value) {
        setSearchResult(null);
      } else {
        setSearchResult(sectionsListItems.filter(({ name }) => name.toLowerCase().includes(value)));
      }
    }),
    [sectionsListItems],
  );

  const items = searchResult ?? sectionsListItems;

  return (
    <Modal.Panel className="lg:top-10 lg:w-[44.375rem] lg:max-h-[43rem] lg:p-5 bg-athens-gray top-3 p-3 w-full max-w-[calc(100%-1.5rem)] max-h-full dark:bg-cod-gray">
      <Modal.Title className="justify-start relative text-xl">
        <div>{$t({ defaultMessage: "Providers" })}</div>
        <Modal.CloseButton onClick={close} />
      </Modal.Title>
      <InputSearch onChange={handleSearch} />
      <div className="lg:-mr-4.5 lg:pr-0.5 lg:tw-scrollbar overflow-y-scroll mt-5">
        <div className="lg:gap-5 lg:py-1 grid grid-cols-3 gap-3">
          {items.map(({ name, id, stringId }) => (
            <ProviderButton
              className="aspect-4/1 lg:aspect-5/1"
              key={id}
              name={name}
              id={id}
              stringId={stringId}
              isActive={pid === id}
              onClick={close}
            />
          ))}
        </div>
      </div>
    </Modal.Panel>
  );
}
