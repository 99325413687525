import { onlineManager } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";

import { Game } from "@entities/game/model";
import { Player } from "@entities/player";
import { PlayerInfo } from "@features/player/player-info";
import { APIError, CLIENT_ERRORS, emitter, useMobile } from "@shared/lib";
import { Logo } from "@shared/ui/logo";
import { SVGIcon } from "@shared/ui/svg-icon";
import { Header } from "@widget/header";
import { JackpotPanel } from "@widget/jackpot-panel";
import { MiniGame, MiniGameContextProvider } from "@widget/mini-game";

import { GamePageControlsMobile } from "./game-page-controls.mobile";

import "./styles.css";

const HAS_HEADER_GAME_ID = ["sport"];

export function GamePage() {
  const { gameId: gameStringId } = useParams({ from: "/game/$gameId" });
  const navigate = useNavigate();
  const [iframeKey, setIframeKey] = useState<string>(Date.now().toString());
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isMobile = useMobile();
  const isMediumScreen = useMobile("(max-width: 1439px)");

  const { data: player } = Player.usePlayer();

  const theme = (localStorage.getItem("theme") as "light" | "dark") ?? "dark";

  const { data } = Game.useGameRun({
    gameStringId,
    place: "game_list",
    lang: localStorage.getItem("locale") ?? "en",
    deviceType: isMobile ? "mobile" : "desktop",
    theme,
    color: theme === "dark" ? "3895f1" : "0069c9",
  });

  const game = Game.getGameByStringId(gameStringId);

  const hasHeader = HAS_HEADER_GAME_ID.includes(gameStringId);

  const gameUrl = data?.result?.url;

  const reloadIframe = () => {
    if (iframeRef.current) {
      setIframeKey(Date.now().toString());
    }
  };

  useEffect(() => {
    if (data?.error) {
      const error = data.error;
      navigate({ to: "/", replace: true, search: true });

      const apiError = new APIError(error.message, {
        code: error.code,
        game: gameStringId,
        PID: player?.id ? `${player.id} / ${player.hallId}` : null,
      });

      emitter.emit("ERROR_MODAL_OPEN", apiError);
    }
  }, [data, gameUrl, navigate, gameStringId, player]);

  useEffect(() => {
    const unsubThemeChange = emitter.on("THEME_CHANGED", reloadIframe);
    const unsubLocaleChange = emitter.on("SET_LOCALE", reloadIframe);

    if (!onlineManager.isOnline()) {
      emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
    }

    return () => {
      onlineManager.subscribe((isOnline) => {
        if (!isOnline) {
          emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
        }
      });

      unsubThemeChange();
      unsubLocaleChange();
    };
  }, []);

  return (
    <>
      <MiniGameContextProvider>
        <MiniGame />
      </MiniGameContextProvider>
      <div className="game-frame fixed inset-0 w-screen h-dvh z-[100] overflow-y-hidden">
        <div className="h-full">
          <div className="flex flex-col h-full">
            {hasHeader ? (
              <Header />
            ) : (
              !isMobile && (
                <div className="bg-mercury flex items-center justify-between px-5 py-3 dark:bg-mine-shaft">
                  <Link to="/">
                    <Logo className="lg:h-10 h-5" />
                  </Link>
                  <div className="flex items-center gap-5 h-full">
                    <PlayerInfo />
                    <Link to="/" search>
                      <SVGIcon
                        className="lg:size-8 size-4 text-science-blue dark:text-picton-blue hover:text-picton-blue dark:hover:text-science-blue"
                        name="close"
                      />
                    </Link>
                  </div>
                </div>
              )
            )}
            {isMediumScreen ? (
              <JackpotPanel.Mobile className="bg-mercury p-0" />
            ) : (
              <JackpotPanel.Desktop className="bg-white p-0 dark:bg-mine-shaft" delimiter="logo" />
            )}
            <div className="grow">
              {gameUrl && (
                <iframe
                  key={iframeKey}
                  ref={iframeRef}
                  className="size-full"
                  id="game-frame"
                  title="game-frame"
                  src={gameUrl}
                  allow="fullScreen"
                  allowFullScreen
                />
              )}
            </div>

            {!hasHeader && isMobile && <GamePageControlsMobile game={game} />}
          </div>
        </div>
      </div>
    </>
  );
}
