import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { Player, usePlayerMoneyFormatter } from "@entities/player";
import { range } from "@shared/lib";
import { SVGIcon } from "@shared/ui";
import { Skeleton } from "@shared/ui/skeleton";
import { translateBonusStatus } from "@widget/profile-modal/lib";

import { NoDataPlaceholder } from "../../no-data-placeholder";

import "./styles.css";

export function Bonuses({ className }: { className?: string }) {
  const formatMoney = usePlayerMoneyFormatter();
  const { intl } = useIntlT();
  const { data: bonusHistory, isLoading } = Player.useBonusHistory();
  const { $t } = useTranslate();

  const isBonusHistoryExist = bonusHistory && bonusHistory.length > 0;

  return (
    <div className={clsx(className, "lg:min-h-0 flex flex-col max-h-full h-full")}>
      {!isBonusHistoryExist && !isLoading && (
        <NoDataPlaceholder
          topText={$t({ defaultMessage: "Your bonus will be here soon" })}
          icon={<SVGIcon name="bonusGift" className="lg:w-24 w-20" />}
        />
      )}

      {isLoading && range(10)((idx) => <Skeleton key={idx} className="bg-silver h-9 mb-2" />)}

      {isBonusHistoryExist && (
        <div className="lg:overflow-y-auto lg:tw-scrollbar">
          <table className="bonuses-table">
            <thead>
              <tr>
                <th>{$t({ defaultMessage: "Date" })}</th>
                <th>{$t({ defaultMessage: "Bonus" })}</th>
                <th>{$t({ defaultMessage: "Wager" })}</th>
                <th>{$t({ defaultMessage: "Amount" })}</th>
                <th>{$t({ defaultMessage: "Status" })}</th>
              </tr>
            </thead>
            <tbody>
              {bonusHistory.map((bonus) => (
                <tr key={bonus.id}>
                  <td>
                    {new Date(bonus.lastStatusChangeAt * 1e3).toLocaleString(intl.locale, {
                      timeStyle: "short",
                      dateStyle: "short",
                    })}
                  </td>
                  <td>{bonus.type}</td>
                  <td>
                    {formatMoney(bonus.initialWager ?? 0, {
                      style: "decimal",
                    })}
                  </td>
                  <td>{formatMoney(bonus.amount ?? 0, { style: "decimal" })}</td>
                  <td>{translateBonusStatus($t, bonus.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
