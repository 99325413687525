import takeBonusGiftBoxImg from "@assets/bonus_gift_box_1x.png";
import takeBonusGlowImg from "@assets/take-bonus-glow.png";
import { Player, usePlayerMoneyFormatter } from "@entities/player";
import { useTranslate } from "@lobby/ocb-intl";
import { useModal } from "@shared/lib";
import { Modal } from "@shared/ui/modal";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { TakeBonusShape, TakeBonusText } from "./components";

export function TakeBonusModal() {
  const { isOpen, close } = useModal("take-bonus");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <TakeBonusComponent />
    </Modal>
  );
}

function TakeBonusComponent() {
  const { $t } = useTranslate();
  const navigate = useNavigate();
  const { close: closeModal } = useModal("take-bonus");
  const formatMoney = usePlayerMoneyFormatter();

  const { data } = Player.useBalances();
  const bonus = data?.bonus as {
    amount: number;
    type: string;
  };
  const activateBonusMutation = Player.useActivateBonus();

  const amount = formatMoney(bonus?.amount);

  function handleActivateBonus() {
    activateBonusMutation.mutate();
    closeModal();
  }

  useEffect(() => {
    if (!bonus) {
      navigate({
        search: (prev) => ({ ...prev, modal: undefined }),
        replace: true,
      });
    }
  }, [bonus, activateBonusMutation, navigate]);

  return (
    <Modal.Panel className="take-bonus-modal" centered>
      <TakeBonusShape className="rounded-[2.5em]">
        <div className="flex flex-col items-center w-full">
          <div className="relative pointer-events-none size-[12.5em]">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[37.5em]">
              <img src={takeBonusGlowImg} width="100%" height="100%" />
            </div>
            <div className="absolute left-1/2 bottom-0 -translate-x-1/2 size-[19.5em]">
              <img
                className="object-contain size-full"
                src={takeBonusGiftBoxImg}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <TakeBonusText className="text-[2.25em] mt-[0.25em]">{bonus?.type}</TakeBonusText>
          <TakeBonusText className="text-[2.5em] mt-[0.25em]">{amount}</TakeBonusText>
          <div className="grid grid-cols-2 gap-5 mt-[1.875em]">
            <button className="take-bonus__take-btn" type="button" onClick={handleActivateBonus}>
              <span>{$t({ defaultMessage: "take" })}</span>
            </button>
            <button className="take-bonus__later-btn" type="button" onClick={closeModal}>
              <span>{$t({ defaultMessage: "later" })}</span>
            </button>
          </div>
        </div>
      </TakeBonusShape>
    </Modal.Panel>
  );
}
