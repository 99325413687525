import { clsx } from "clsx";

interface IButtonProps {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  type?: "primary" | "secondary" | "ghost" | "danger" | "warning" | "default";
  htmlType?: "button" | "submit" | "reset";
  onClick?: VoidFunction;
}

export function Button({
  className,
  children,
  disabled,
  type,
  htmlType = "button",
  onClick,
}: IButtonProps) {
  let buttonTypeClass = "";
  switch (type) {
    case "primary":
      buttonTypeClass = "btn_primary";
      break;
    case "secondary":
      buttonTypeClass = "btn_secondary";
      break;
    case "ghost":
      buttonTypeClass = "btn_ghost";
      break;
    case "danger":
      buttonTypeClass = "btn_danger";
      break;
    case "warning":
      buttonTypeClass = "btn_warning";
      break;
    case "default":
    default:
      buttonTypeClass = "btn_default";
  }

  return (
    <button
      className={clsx("btn", buttonTypeClass, className)}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
}
