import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useContext, useState } from "react";

import { Player, PlayerSettingsContext } from "@entities/player";
import { PlayerBalance } from "@features/player/player-balance";
import { emitter } from "@shared/lib";
import { SVGIcon } from "@shared/ui";

import type { PropsWithChildren} from "react";

export function ProfilePlayerBalanceView({ className }: { className?: string }) {
  const { $t } = useTranslate();

  const playerSettings = useContext(PlayerSettingsContext);

  const { data: player } = Player.usePlayer();
  const { refetch, isFetching } = Player.useBalances();

  const [isDummyFetching, setIsDummyFetching] = useState(false);

  const playerBalanceExTimeoutRefetch = async () => {
    setIsDummyFetching(true);
    await refetch();
    setTimeout(() => setIsDummyFetching(false), 1000);
  };

  return (
    <div
      className={clsx(
        "lg:rounded-sm lg:p-5 bg-athens-gray rounded-xs p-3 dark:bg-mine-shaft",
        className,
      )}
    >
      <div className="lg:text-base font-bold truncate text-lg">
        {String(player?.displayableName)}
      </div>
      <div className="lg:mt-3 lg:text-base flex items-center justify-between font-bold text-lg text-science-blue dark:text-dodger-blue uppercase">
        <div>{$t({ defaultMessage: "Balance" })}</div>
        <PlayerBalance />
      </div>
      <div className="grid grid-cols-2 gap-5 mt-3">
        <BalanceControlButton
          onClick={playerBalanceExTimeoutRefetch}
          disabled={isFetching || isDummyFetching}
        >
          <SVGIcon
            className={`${(isFetching || isDummyFetching) && "animate-spin"} *:size-4`}
            name="reload"
          />
          <span>{$t({ defaultMessage: "Refresh" })}</span>
        </BalanceControlButton>
        <BalanceControlButton
          onClick={() =>
            emitter.emit("PLAYER_BALANCE_VISIBILITY_CHANGED", !playerSettings.isBalanceVisible)
          }
        >
          <div className="flex absolute">
            {playerSettings.isBalanceVisible ? (
              <>
                <SVGIcon name="closedEye" />
                <span className="ml-1.5">{$t({ defaultMessage: "Hide" })}</span>
              </>
            ) : (
              <>
                <SVGIcon name="openedEye" />
                <span className="ml-1.5">{$t({ defaultMessage: "Show" })}</span>
              </>
            )}
          </div>
          <div className="invisible flex">
            <SVGIcon name="closedEye" />
            <div>
              <div className="ml-1.5">{$t({ defaultMessage: "Hide" })}</div>
              <div className="ml-1.5">{$t({ defaultMessage: "Show" })}</div>
            </div>
          </div>
        </BalanceControlButton>
      </div>
    </div>
  );
}

interface IBalanceControlButton extends PropsWithChildren {
  disabled?: boolean;
  onClick: VoidFunction;
}

function BalanceControlButton({ onClick, disabled, children }: IBalanceControlButton) {
  return (
    <button
      className="lg:h-10 lg:text-sm bg-white border-mercury border-1 rounded-sm flex flex-center gap-2 px-3 font-medium relative text-xs h-8 dark:bg-charcoal dark:border-charcoal"
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
