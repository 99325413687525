import * as Sentry from "@sentry/react";
import { clsx } from "clsx";

import { Icons } from "./icons";

interface ISVGIconProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  name: keyof typeof Icons;
}

export function SVGIcon({ className, name, onClick }: ISVGIconProps) {
  const Icon = Icons[name];

  return (
    <Sentry.ErrorBoundary fallback={<pre>%</pre>}>
      <div className={clsx(className, "svg-icon flex-center empty:hidden")} onClick={onClick}>
        <Icon />
      </div>
    </Sentry.ErrorBoundary>
  );
}

export type TIconName = ISVGIconProps["name"];
