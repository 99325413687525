import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { useEffect, useState } from "react";

import { emitter } from "@shared/lib";
import { Modal } from "@shared/ui/modal/modal";

import type { ICongratsModalProps } from "@shared/lib/types";
import { BackgroundLayer, TakeButton } from "./components";

export function CongratsModal() {
  const { $t } = useTranslate();

  const [isOpened, setIsOpened] = useState(false);
  const [params, setParams] = useState<ICongratsModalProps>({} as ICongratsModalProps);

  const { message, value, onTake } = params ?? {};

  const congratsText = $t({ defaultMessage: "congrats" }) + skipIntl("!");

  function handleTake() {
    onTake();
    setIsOpened(false);
  }

  useEffect(() => {
    const unbind = emitter.on("CONGRATS_MODAL_OPEN", (params) => {
      setParams(params);
      setIsOpened(true);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />
      <Modal.Panel className="congrats-modal" centered>
        <BackgroundLayer>
          <div className="flex flex-col items-center gap-[0.5em] pt-[4.375em] size-full leading-none font-bold italic text-center uppercase drop-shadow-[0_0.125em_0.0625em_#000000a6]">
            <p
              className="text-white text-[3em]"
              style={{
                fontSize: `${Math.min(3, 30 / String(congratsText).length)}em`,
              }}
            >
              {congratsText}
            </p>
            <p className="bg-gradient-to-b from-vis-vis to-casablanca bg-clip-text text-transparent text-[2em] whitespace-pre-line box-decoration-clone">
              {message}
            </p>
            <p
              className="text-white text-[3em] whitespace-nowrap"
              style={{
                fontSize: `${Math.min(3, 36 / String(value).length)}em`,
              }}
            >
              {value}
            </p>
          </div>
        </BackgroundLayer>
        <div className="congrats-modal__wreath" />
        <div className="congrats-modal__coins" />
        <div className="absolute bottom-[27%] left-1/2 -translate-x-1/2">
          <TakeButton onClick={handleTake}>{$t({ defaultMessage: "take" })}</TakeButton>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
