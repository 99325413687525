import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createRootRouteWithContext,
  defer,
  Navigate,
  Outlet,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect } from "react";

import {
  ensureUserIsAuthenticated,
  validateSearchModal,
  validateSearchProfileSection,
} from "@app/lib";
import { loadFortuneWheelSettings } from "@app/lib/loaders";
import { ContactsModal } from "@features/contacts-modal";
import { ErrorModal } from "@features/error-modal";
import { TakeBonusButton, TakeBonusModal } from "@features/take-bonus";
import { WarningModal } from "@features/warning-modal";
import { emitter } from "@shared/lib";
import { ProfileModal, type TProfileSection } from "@widget/profile-modal";

import type { QueryClient } from "@tanstack/react-query";

type GlobalSearch = {
  modal?: string;
  cid: number;
  pid: number;
  game: string;
  redirect: string;
  profileSection?: TProfileSection;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  onError: console.error,
  beforeLoad: async ({ cause }) => cause === "enter" && (await ensureUserIsAuthenticated()),
  loader: async ({ context }) => defer(loadFortuneWheelSettings(context.queryClient)),
  notFoundComponent: () => <Navigate to="/" replace params={{}} />,
  validateSearch: (search: Record<string, unknown>): GlobalSearch => ({
    modal: validateSearchModal(search),
    cid: search.cid as number,
    pid: search.pid as number,
    game: search.game as string,
    redirect: search.redirect as string,
    profileSection: validateSearchProfileSection(search),
  }),
});

function RootComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    return emitter.on("PLAYER_LOGOUT", () => navigate({ to: "/", replace: true }));
  }, [navigate]);

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", width: 0, height: 0 }}>
        <defs>
          <linearGradient id="linearGradientMetallic" gradientTransform="rotate(90)">
            <stop className="[stop-color:#0069c9] dark:[stop-color:#fff]" offset="0" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#fff]" offset="0.5" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#777]" offset="1" />
          </linearGradient>
          <linearGradient id="linearGradientMetallic-90" gradientTransform="rotate(90)">
            <stop className="[stop-color:#0069c9] dark:[stop-color:#777]" offset="0" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#fff]" offset="0.5" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#fff]" offset="1" />
          </linearGradient>
          <linearGradient id="linearGradientMetallic2">
            <stop className="[stop-color:#0069c9] dark:[stop-color:#777]" offset="0" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#fff]" offset="0.5" />
            <stop className="[stop-color:#0069c9] dark:[stop-color:#b4b4b4]" offset="1" />
          </linearGradient>
        </defs>
      </svg>
      <ProfileModal />
      <TakeBonusModal />
      <ContactsModal />
      <WarningModal />

      <ErrorModal />
      <TakeBonusButton className="lg:right-10 lg:bottom-10 fixed bottom-3 right-3 z-[999] mobile-only:text-xs" />
      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </>
  );
}
