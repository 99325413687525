import { GameCard } from "@entities/game";
import { range } from "@shared/lib";

export function GamesContainerSkeleton() {
  return (
    <div className="rounded-sm bg-athens-gray dark:bg-outer-space">
      <div className="flex items-center justify-between">
        <div className="rounded-sm animate-pulse bg-alto dark:bg-bright-gray w-60 h-7" />
      </div>

      <div className="mt-5">
        <div className="grid gap-x-5 gap-y-10 grid-flow-col auto-cols-[9.4375rem] overflow-x-auto snap-x h-[11.625rem]">
          {range(18)((idx) => (
            <GameCard.Skeleton key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
}
