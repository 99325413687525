import clsx from "clsx";

import { useModal } from "@shared/lib";

export function MobileMenuButton({ className }: { className?: string }) {
  const { open: openProfile } = useModal("profile");

  return (
    <div
      className={clsx(
        className,
        "flex flex-col gap-[0.1875rem] w-5 *:rounded-xs *:bg-science-blue *:h-[0.1875rem] *:dark:bg-gradient-to-r *:dark:from-boulder *:dark:via-white *:dark:to-nobel",
      )}
      onClick={openProfile}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
