import { UnwagerGameList } from "./unwager-game-list";

export function Info() {
  return (
    <div className="lg:max-h-full flex flex-col min-h-0">
      <div className="lg:overflow-y-auto lg:tw-scrollbar">
        <UnwagerGameList />
      </div>
    </div>
  );
}
