import { memo } from "react";

import { Logo, Modal } from "@shared/ui";

import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";

import type { TProfileSectionItem } from "../common";

interface IProfileComponentDesktopProps {
  currentSection: TProfileSectionItem;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentDesktop = memo(function ProfileComponentDesktop({
  currentSection = {} as TProfileSectionItem,
  showLoadingOverlay,
  close,
}: IProfileComponentDesktopProps) {
  const sections = useProfileSections();

  return (
    <Modal.Panel className="p-10 top-18 w-[62.5rem] h-[45.625rem]">
      <Modal.Title className="justify-between">
        <Modal.CloseButton onClick={close} />
        <Logo className="h-11" />
      </Modal.Title>

      <div className="grid grid-cols-[minmax(18.5rem,auto)_1fr] gap-10 min-h-0 *:min-h-0">
        <div className="flex flex-col">
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails className="mt-5" />
          <div className="mt-5 min-h-0">
            <ProfileSectionControls currentSectionId={currentSection.id} items={sections} />
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="font-bold text-xl uppercase leading-none mb-5 dark:text-metallic">
            {currentSection.title}
          </div>
          {currentSection.component}
        </div>
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
