import { useTranslate } from "@lobby/ocb-intl";
import { useIsMutating } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import { LoginForm } from "@features/log-in-form";
import { emitter, useAuth, useModal } from "@shared/lib";
import { Logo } from "@shared/ui/logo";
import { Modal } from "@shared/ui/modal";

export function LogInModal() {
  const { isOpen, close } = useModal("log-in");

  useEffect(() => {
    const unbind = emitter.on("PLAYER_LOGIN", close);
    return unbind;
  }, [close]);

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <LogInModalComponent />
    </Modal>
  );
}

function LogInModalComponent() {
  const navigate = useNavigate();
  const { $t } = useTranslate();

  const { isAuth } = useAuth();

  const isLogInPending = useIsMutating({ mutationKey: ["Player.logIn"] }) > 0;

  useEffect(() => {
    if (isAuth) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isAuth]);

  return (
    <Modal.Panel className="login-modal">
      <Logo className="h-10 mx-auto" />
      <div className="mt-5 font-bold text-2xl leading-none text-center uppercase dark:text-metallic dark:drop-shadow-[0_0.125rem_0.0625rem_rgba(0,0,0,0.65)]">
        {$t({ defaultMessage: "Log In" })}
      </div>
      <LoginForm className="mt-5" />

      <Modal.PendingOverlay isActive={isLogInPending} />
    </Modal.Panel>
  );
}
