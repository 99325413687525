import fortuneWheelBtnImg from "@assets/fw-btn-bg.png";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, useFortuneWheelCountdown } from "@shared/lib";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data } = FortuneWheel.useWheelState();
  const { data: settings } = FortuneWheel.useWheelSettings();
  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);

  const isDepositAbsent = settings?.state === "make_deposit";
  const isCountdownActive = countdown > 0;

  return (
    <div className="fortune-wheel-btn mt-5 relative h-[5.625rem] overflow-hidden">
      <div className="fortune-wheel-btn__background-layer" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img
          className="object-contain size-36 animate-step-spin"
          src={fortuneWheelBtnImg}
          alt="fortune-wheel-preview"
          width="100%"
          height="100%"
        />
      </div>
      <Link
        className="gradient-primary hover:gradient-primary-highlighted border-2 rounded-sm border-white py-1 px-2.5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-44 hover:drop-shadow-[0_0_1.5rem_#3895f1]"
        to="/fortune-wheel"
      >
        <div className="text-white font-bold italic uppercase [text-shadow:0_0_1.25rem_#13c7bc] text-center whitespace-nowrap">
          {isCountdownActive
            ? formatTime(countdown)
            : isDepositAbsent
              ? $t({ defaultMessage: "fortune wheel" })
              : $t({ defaultMessage: "ready to spin" })}
        </div>
      </Link>
      <hr className="border-none [background-image:linear-gradient(to_right,transparent,#5da8f4,transparent)] absolute top-0 left-0 h-1 w-full dark:[background-image:linear-gradient(to_right,transparent,#fff,transparent)]" />
      <hr className="border-none [background-image:linear-gradient(to_right,transparent,#5da8f4,transparent)] absolute bottom-0 left-0 h-1 w-full dark:[background-image:linear-gradient(to_right,transparent,#fff,transparent)]" />
    </div>
  );
}
