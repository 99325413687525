import type { PropsWithChildren } from "react";

export function Skeleton({ className, children }: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={`lg:rounded-sm bg-mercury dark:bg-bright-gray rounded-xs animate-pulse ${className}`}
    >
      {children}
    </div>
  );
}
