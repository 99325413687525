import { useTranslate } from "@lobby/ocb-intl";
import { useMemo } from "react";

import { useMobile } from "@shared/lib";
import { ProfileSection } from "@widget/profile-modal/lib";
import { Info } from "@widget/profile-modal/ui/common/profile-sections/info";

import { BetHistory } from "./bet-history";
import { Bonuses } from "./bonuses";
import { GameHistory } from "./game-history";
import { MemberCard } from "./member-card";
import { Settings } from "./settings";

import type { TProfileSection } from "@widget/profile-modal/lib";

export type TProfileSectionItem = {
  id: TProfileSection;
  title: string;
  icon: string;
  component: React.ReactNode;
};

export const useProfileSections = (): TProfileSectionItem[] => {
  const { $t } = useTranslate();
  const isMobile = useMobile();

  return useMemo(() => {
    const sections = [
      {
        id: ProfileSection.Bonuses,
        title: $t({ defaultMessage: "Bonuses" }),
        icon: "order",
        component: <Bonuses />,
      },
      {
        id: ProfileSection.GameHistory,
        title: $t({ defaultMessage: "Game history" }),
        icon: "gamepad",
        component: <GameHistory />,
      },
      {
        id: ProfileSection.SportBetsHistory,
        title: $t({ defaultMessage: "Sport Bets history" }),
        icon: "trophySolid",
        component: <BetHistory />,
      },
      {
        id: ProfileSection.Info,
        title: $t({ defaultMessage: "Info" }),
        icon: "info",
        component: <Info />,
      },
      {
        id: ProfileSection.MemberCard,
        title: $t({ defaultMessage: "Member card" }),
        icon: "memberCard",
        component: <MemberCard />,
      },
      {
        id: ProfileSection.Settings,
        title: $t({ defaultMessage: "Settings" }),
        icon: "settings",
        component: <Settings />,
      },
    ];

    if (!isMobile) {
      return sections.filter((section) => section.id !== ProfileSection.MemberCard);
    }

    return sections;
  }, [$t, isMobile]);
};
