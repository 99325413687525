import clsx from "clsx";

interface IFavouriteBtnProps {
  className?: string;
  isFavourite: boolean;
  onClick: VoidFunction;
}

export function FavouriteBtn({ className, isFavourite, onClick }: IFavouriteBtnProps) {
  return (
    <button className={clsx(className, "group/fav")} type="button" onClick={onClick}>
      <svg
        className="lg:w-6 lg:h-5.5 w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 22"
        width="24"
        height="22"
      >
        <path
          className={clsx(
            "group-hover/fav:stroke-picton-blue",
            isFavourite ? "fill-radical-red stroke-white" : "fill-white",
          )}
          d="M2.42 2.58a6.25 6.25 0 0 0 0 8.84l.883.883 8.84 8.84v-.001l8.838-8.839.884-.884a6.25 6.25 0 1 0-8.839-8.838l-.884.883-.884-.884a6.25 6.25 0 0 0-8.838 0Z"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
