import { useTranslate } from "@lobby/ocb-intl";

import { Lobby } from "@entities/lobby";
import { useModal } from "@shared/lib";
import { Modal } from "@shared/ui/modal/modal";

export function ContactsModal() {
  const { isOpen, close } = useModal("contacts");
  const { $t } = useTranslate();
  const lobby = Lobby.useLobby();
  const contacts = lobby.data?.contacts ?? "";

  // if (!contacts) return null;

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <Modal.Panel className="lg:top-10 lg:w-[44.375rem] max-h-[44.375rem] w-screen top-0">
        <Modal.Title className="justify-center relative">
          {$t({
            defaultMessage: "Contacts",
          })}
          <Modal.CloseButton onClick={close} />
        </Modal.Title>
        <div className="lg:py-1 lg:tw-scrollbar overflow-y-auto">
          <div className="flex flex-col gap-5 px-5 mt-5">
            <p className="whitespace-pre-wrap max-w-3xl">{contacts}</p>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
