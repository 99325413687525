import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { useContext, type PropsWithChildren } from "react";

import { LobbySettingsContext } from "@entities/lobby";
import { getProviderImageSrc } from "@shared/lib";

interface IProviderButtonProps {
  className?: string;
  name: string;
  id: number;
  isActive?: boolean;
  stringId: string;
  onClick?: () => void;
}

export function ProviderButton({
  className,
  name,
  id,
  isActive,
  stringId,
  onClick,
}: PropsWithChildren<IProviderButtonProps>) {
  const navigate = useNavigate();
  const lobbyContext = useContext(LobbySettingsContext);

  return (
    <button
      className={clsx(
        className,
        isActive ? "border-science-blue" : "border-transparent",
        "lg:hover:bg-alto lg:dark:hover:bg-dove-gray border-2 bg-mercury flex items-center justify-center rounded-sm select-none *:size-full dark:bg-charcoal",
      )}
      type="button"
      title={name}
      onClick={() => {
        navigate({
          replace: true,
          search: (prev) => ({
            ...prev,
            game: undefined,
            cid: undefined,
            pid: prev.pid === id ? undefined : id,
          }),
        });

        if (onClick) {
          onClick();
        }
      }}
    >
      <img src={getProviderImageSrc(lobbyContext.theme, stringId)} alt={stringId} />
    </button>
  );
}
