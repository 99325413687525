import { useTranslate } from "@lobby/ocb-intl";

import { Skeleton } from "@shared/ui";

interface IRulesSectionProps {
  rules?: { general: string; daily: string };
}

function RuleContent({
  className,
  title,
  description,
}: {
  className?: string;
  title: string;
  description: string | undefined;
}) {
  return (
    <div className={className}>
      <div className="font-bold text-2xs text-dodger-blue uppercase mb-3">{title}</div>
      {description ? (
        <p className="text-3xs text-white whitespace-pre-line">{description}</p>
      ) : (
        <div className="flex flex-col gap-1 w-40 *:dark:bg-big-stone *:h-3 *:w-full">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </div>
  );
}

export function RulesSectionMobile({ rules }: IRulesSectionProps) {
  const { $t } = useTranslate();

  return (
    <div className="fortune-wheel-rules-mobile">
      <div className="fortune-wheel-rules-mobile__background">
        <RuleContent title={$t({ defaultMessage: "how it works" })} description={rules?.general} />
        <RuleContent
          className="text-right"
          title={$t({ defaultMessage: "daily wheel rules" })}
          description={rules?.daily}
        />
      </div>
    </div>
  );
}
