export const WHEEL_DIAMETER = 770;
export const WHEEL_RADIUS = WHEEL_DIAMETER / 2;

export const WHEEL_INNER_DIAMETER = WHEEL_DIAMETER * 0.87;
export const WHEEL_INNER_RADIUS = WHEEL_INNER_DIAMETER / 2;

export const WHEEL_REVOLUTIONS_TO_WIN = 3;
export const WHEEL_INITIAL_VELOCITY = 3;
export const WHEEL_SECTORS_REPEAT_COUNT = 3;

export const sectorFillings = ["#e8358b", "#8c30c5", "#ff8200", "#f2c903", "#91d10e", "#404040"];

export const sectorColors: [string, string][] = [
  ["#e8358b", "#e8358b"],
  ["#8c30c5", "#8c30c5"],
  ["#ff8200", "#ff8200"],
  ["#f2c903", "#f2c903"],
  ["#91d10e", "#91d10e"],
  ["#404040", "#404040"],
];
