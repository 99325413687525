import { clsx } from "clsx";
import { useEffect, useState } from "react";

import { Button } from "../button";

interface IArrowProps {
  className?: string;
  disabled?: boolean;
  direction: "up" | "down" | "left" | "right";
  onClick?: VoidFunction;
}

function Arrow({ className, disabled, direction, onClick }: IArrowProps) {
  let rotation = 0;
  switch (direction) {
    case "up":
      rotation = 90;
      break;
    case "right":
      rotation = 180;
      break;
    case "down":
      rotation = 270;
      break;
    default:
      rotation;
  }

  return (
    <Button
      className="pagination-arrow group"
      type="secondary"
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="10"
        fill="none"
        viewBox="0 0 6 10"
        style={{
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <path
          className="group-disabled:fill-boulder dark:group-disabled:fill-[#5a5a5a]"
          d="M.968 6.414a2 2 0 0 1 0-3.13L4.378.57A1 1 0 0 1 6 1.354v6.99a1 1 0 0 1-1.623.782L.967 6.414Z"
          fill={`url(#linearGradientMetallic-90)`}
        />
      </svg>
    </Button>
  );
}

interface IPaginationProps {
  className?: string;
  componentRef: HTMLDivElement | null;
}

function PaginationComponent({ className, componentRef }: IPaginationProps) {
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "progress">("start");

  function onClickPrev() {
    componentRef?.scrollBy({
      left: -componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  function onClickNext() {
    componentRef?.scrollBy({
      left: componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function scrollListener(ev: Event) {
      const { scrollWidth, scrollLeft, clientWidth } = ev.currentTarget as HTMLDivElement;

      if (scrollLeft === 0) {
        setScrollPosition("start");
      } else if (Math.abs(scrollWidth - scrollLeft - clientWidth) < 1) {
        setScrollPosition("end");
      } else {
        setScrollPosition("progress");
      }
    }

    componentRef?.addEventListener("scroll", scrollListener);
    return () => componentRef?.removeEventListener("scroll", scrollListener);
  }, [componentRef]);

  return (
    <div className={clsx(className, "flex items-center justify-center gap-2")}>
      <Arrow direction="left" onClick={onClickPrev} disabled={scrollPosition === "start"} />
      <Arrow direction="right" onClick={onClickNext} disabled={scrollPosition === "end"} />
    </div>
  );
}

export const Pagination = Object.assign(PaginationComponent, {
  Arrow,
});
