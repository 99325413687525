import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { JackpotTicker, useJackpotsContext } from "@entities/jackpot";
import { useAuth } from "@shared/lib";
import { Skeleton } from "@shared/ui/skeleton";

import type { ISlotView } from "@entities/jackpot";
import type { ReactNode } from "react";

export function JackpotPanelDesktop({
  className,
  delimiter,
}: Omit<IJackpotPanelComponentProps, "slots">) {
  const { isAuth } = useAuth();
  const { slots } = useJackpotsContext();

  if (!isAuth) return null;

  return slots ? (
    <JackpotPanelComponent className={className} delimiter={delimiter} slots={slots} />
  ) : (
    <Skeleton className="bg-silver h-20" />
  );
}

interface IJackpotPanelComponentProps {
  className?: string;
  slots: ISlotView[];
  delimiter?: "title" | "logo";
}

function JackpotPanelComponent({
  className,
  slots,
  delimiter = "title",
}: IJackpotPanelComponentProps) {
  const { $t } = useTranslate();

  const mini = slots[0];
  const major = slots[1];
  const grand = slots[2];
  const ultimate = slots[3];

  let DelimiterComponent: ReactNode = <></>;
  if (delimiter === "title") {
    DelimiterComponent = (
      <div className="jackpot-panel_delimiter_title text-5xl font-oswald font-semibold tracking-tight leading-none text-center uppercase mx-3 dark:text-metallic lang-el:font-advent lang-el:font-extrabold">
        {$t({ defaultMessage: "jackpot prizes" })}
      </div>
    );
  } else if (delimiter === "logo") {
    DelimiterComponent = (
      <div className="jackpot-panel_delimiter_logo bg-mercury p-2 h-full w-16 dark:bg-mine-shaft">
        <div className="bg-mine-shaft size-full">
          <div className="flex items-center justify-center size-full">
            <svg
              className="w-[70%]"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 70 44"
            >
              <defs>
                <linearGradient
                  id="paint0_linear_266_3796"
                  x1="50.45"
                  x2="50.45"
                  y1="10.6"
                  y2="35.01"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" />
                  <stop offset=".5" stopColor="#fff" />
                  <stop offset="1" stopColor="#777" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_266_3796"
                  x1="18"
                  x2="18"
                  y1="43.23"
                  y2=".03"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0043a7" />
                  <stop offset=".48" stopColor="#2776c4" />
                  <stop offset="1" stopColor="#2b72de" />
                </linearGradient>
              </defs>
              <path
                fill="url(#paint0_linear_266_3796)"
                d="M69.7.1H47.5c-2.6-.4-8.04.13-9 5.4-.96 5.29-5.2 23.8-7.2 32.4-.4 1.8.12 5.4 5.4 5.4h23.4l1.8-5.4H45.1l7.2-32.4h15.6L69.7.1Z"
              />
              <path fill="#d9d9d9" d="M0 5.43V.03h36l-1.2 5.4H24l-7.8 37.8h-15l8.4-37.8H0Z" />
              <path
                fill="url(#paint1_linear_266_3796)"
                d="M0 5.43V.03h36l-1.2 5.4H24l-7.8 37.8h-15l8.4-37.8H0Z"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("jackpot-panel", className)}>
      <div className="grid grid-cols-[1fr_auto_1fr] items-center">
        <div className="jackpot-panel__ticker-group pr-0">
          <JackpotTicker slot={mini} />
          <JackpotTicker slot={major} />
        </div>

        {DelimiterComponent}

        <div className="jackpot-panel__ticker-group pl-0">
          <JackpotTicker direction="right" slot={grand} />
          <JackpotTicker direction="right" slot={ultimate} />
        </div>
      </div>
    </div>
  );
}
