import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useState } from "react";

import { APIError, emitter, useErrorTranslate } from "@shared/lib";
import { Button, Modal, SVGIcon } from "@shared/ui";

export function ErrorModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [error, setError] = useState<Error>();
  const { formatMessage } = useErrorTranslate();

  useEffect(() => {
    const unbind = emitter.on("ERROR_MODAL_OPEN", (error) => {
      setIsOpened(true);
      setError(error);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel className="error-modal" centered>
        <div className="error-modal__inner-background">
          <div className="lg:px-7.5 px-5 py-5">
            <div className="flex gap-2.5">
              <div className="shrink-0 w-17 h-15 *:size-full">
                <SVGIcon name="redCard" />
              </div>

              <div>
                <div className="lg:text-xl font-bold text-base text-radical-red">
                  {$t({ defaultMessage: "error" }).toUpperCase()}
                </div>

                <div className="lg:text-base text-sm font-bold mt-1">
                  <div className="whitespace-break-spaces">{formatMessage(error!)}</div>
                </div>

                <div className="lg:text-base text-xs mt-2">
                  {error instanceof APIError && error?.details && (
                    <div>
                      {Object.entries(error.details).map(([key, value]) => (
                        <div key={key}>
                          <span className="capitalize">{key}: </span>
                          <span> {String(value)}</span>
                        </div>
                      ))}
                    </div>
                  )}

                  <div>
                    <span className="lg:text-xs text-ebony-clay text-2xs dark:text-athens-gray">
                      {new Date().toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button className="mx-auto mt-6" type="danger" onClick={() => setIsOpened(false)}>
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
