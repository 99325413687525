import { PropsWithChildren } from "react";

interface ITakeBonusShapeProps extends PropsWithChildren {
  className?: string;
  onClick?: VoidFunction;
}

export function TakeBonusShape({ className, children, onClick }: ITakeBonusShapeProps) {
  return (
    <div className={`take-bonus-shape ${className}`} onClick={onClick}>
      <div className="take-bonus-shape__inner">{children}</div>
    </div>
  );
}

export function TakeBonusText({ className, children }: PropsWithChildren<{ className?: string }>) {
  return <p className={`take-bonus-text ${className}`}>{children}</p>;
}
