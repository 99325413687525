import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";

import { Logo } from "@shared/ui";
import { Pagination } from "@shared/ui/pagination";

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <footer className={clsx(className, "bg-athens-gray dark:bg-mine-shaft")}>
      <div className="lg:max-w-screen-3xl mx-auto lg:p-5 p-3">
        <div className="lg:contents flex flex-col gap-2">
          <div className="lg:gap-5 flex-c-sb gap-3">
            <Link className="shrink-0" to="/">
              <Logo className="lg:h-10 h-5" />
            </Link>

            {/*for formatjs same key*/}
            <span className="hidden">
              {$t({ defaultMessage: "18+" })}
              {$t({
                defaultMessage:
                  // eslint-disable-next-line formatjs/enforce-placeholders
                  "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
              })}
              {$t({ defaultMessage: "game" })}
              {$t({ defaultMessage: "responsibly" })}
            </span>

            <div
              className="lg:text-sm lg:gap-5 gap-1 flex-center text-2xs text-science-blue uppercase font-medium cursor-pointer whitespace-nowrap"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span className="dark:text-metallic">{$t({ defaultMessage: "Back to top" })}</span>
              <Pagination.Arrow className="lg:size-8 size-5" direction="up" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
