import { useIntlT, useTranslate } from "@lobby/ocb-intl";

import { usePlayerMoneyFormatter } from "@entities/player";
import { SVGIcon } from "@shared/ui";

const winnersFeedFixture = [
  {
    game: "Amazing Money Mach",
    player: "104*****476",
    time: Date.now(),
    bet: 2000002,
    odds: 0.75,
    winners: 12000002,
  },
  {
    game: "Speed Baccarat 16",
    player: "J**qc",
    time: Date.now(),
    bet: 20002,
    odds: 1.25,
    winners: 220002,
  },
  {
    game: "Amazing Money Mach",
    player: "104*****476",
    time: Date.now(),
    bet: 2000002,
    odds: 0.75,
    winners: 12000003,
  },
  {
    game: "Speed Baccarat 16",
    player: "J**qc",
    time: Date.now(),
    bet: 20002,
    odds: 1.25,
    winners: 220002,
  },
  {
    game: "Amazing Money Mach",
    player: "104*****476",
    time: Date.now(),
    bet: 2000002,
    odds: 0.75,
    winners: 12000002,
  },
];

export function WinnersFeed({ className }: { className?: string }) {
  const { intl } = useIntlT();
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <div className={`bg-athens-gray rounded-sm p-5 ${className || ""}`.trimEnd()}>
      <div>
        <div className="flex items-center gap-1 text-science-blue dark:text-metallic">
          <SVGIcon className="size-4" name="crown" />
          <span className="leading-none text-xl font-bold uppercase">Winners feed</span>
        </div>
        <div className="mt-3">
          <table className="winners-feed-table">
            <thead className="bg-transparent bg-none">
              <tr>
                <th>{$t({ defaultMessage: "Game" })}</th>
                <th>{$t({ defaultMessage: "Player" })}</th>
                <th>{$t({ defaultMessage: "Time" })}</th>
                <th>{$t({ defaultMessage: "Bet" })}</th>
                <th>{$t({ defaultMessage: "Odds" })}</th>
                <th>{$t({ defaultMessage: "Winners" })}</th>
              </tr>
            </thead>
            <tbody>
              {winnersFeedFixture.map((winner, idx) => (
                <tr key={idx}>
                  <td>{winner.game}</td>
                  <td>{winner.player}</td>
                  <td>
                    {new Date(winner.time).toLocaleString(intl.locale, {
                      timeStyle: "short",
                      dateStyle: "short",
                    })}
                  </td>
                  <td>
                    {formatMoney(winner.bet ?? 0, {
                      style: "decimal",
                    })}
                  </td>
                  <td>{winner.odds}</td>
                  <td>
                    {formatMoney(winner.winners ?? 0, {
                      style: "decimal",
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
