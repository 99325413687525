import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useState } from "react";

import { emitter } from "@shared/lib";
import { Button, Modal } from "@shared/ui";

export function WarningModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unbind = emitter.on("WARNING_MODAL_OPEN", (message) => {
      setIsOpened(true);
      setMessage(message);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel className="warning-modal" centered>
        <div className="warning-modal__inner-background">
          <div className="lg:px-7.5 lg:py-3 px-5 py-5">
            <div>
              <div>
                <div className="lg:text-xl text-center font-bold text-base text-yellow-orange">
                  {$t({ defaultMessage: "warning" }).toUpperCase()}
                </div>
                <div className="lg:text-base text-sm mt-2">
                  <div className="whitespace-break-spaces">{message}</div>
                </div>
              </div>
            </div>
            <Button className="mx-auto mt-6" type="warning" onClick={() => setIsOpened(false)}>
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
