import { usePlayerMoneyFormatter } from "@entities/player";

import type { ISlotView } from "@entities/jackpot";

interface IJackpotTickerProps {
  className?: string;
  slot: ISlotView;
  flip?: boolean;
  direction?: "left" | "right";
}

export function JackpotTicker({ className, slot, direction = "left" }: IJackpotTickerProps) {
  const formatMoney = usePlayerMoneyFormatter();

  const type = slot.name;
  const value = formatMoney(slot.value);

  return (
    <div
      className={`jackpot-ticker jackpot-ticker_type_${type} jackpot-ticker_direction_${direction}`}
    >
      <div className="lg:h-8.5 flex items-center h-5.5 *:h-full mobile-only:gap-x-1">
        <span className="jackpot-ticker__title ">{type}</span>
        <div className="jackpot-ticker__value-background">
          <span className="jackpot-ticker__value">{value}</span>
        </div>
      </div>
    </div>
  );
}
