import { useMediaQuery } from "react-responsive";

export function useMobile(query = "(max-width: 1023px)") {
  return useMediaQuery({
    query,
  });
}

export function useMobileLandscape() {
  return useMediaQuery({
    query: "(max-width: 1023px) and (orientation:landscape)",
  });
}
