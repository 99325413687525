import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { memo, useEffect, useRef, useState } from "react";

import { GameCard } from "@entities/game";
import { useNavigateToCategory } from "@features/category/category-button";
import { CategoryIcon } from "@features/category/category-icon";
import { range } from "@shared/lib";
import { Button } from "@shared/ui";
import { Pagination } from "@shared/ui/pagination";

import type { Output } from "@lobby/api-client/src/codegen";

import "./styles.css";

interface IGameListProps {
  className?: string;
  expanded?: boolean;
  expandable?: boolean;
  categoryId: string | number;
  type: string;
  label: string;
  items: Output<"Game.getList">["data"] | undefined;
}

export const GamesContainer = memo(function GameList({
  className,
  items,
  expanded,
  expandable,
  categoryId,
  type,
  label,
}: IGameListProps) {
  const { $t } = useTranslate();
  const navigateToCategory = useNavigateToCategory();
  const [itemsCountToRender, setItemsCountToRender] = useState(10);
  const [isContentOverflow, setIsContentOverflow] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollComponentRef = useRef<HTMLDivElement>(null);
  const itemsCountRef = useRef(0);

  const itemsLength = items?.length ?? 0;
  itemsCountRef.current = itemsLength;

  const hasIcon = type !== "search" && type !== "provider";

  useEffect(() => {
    const handleWindowResize = () => {
      const scrollContainer = scrollContainerRef.current;
      const scrollComponent = scrollComponentRef.current;

      if (scrollContainer && scrollComponent) {
        if (!expandable) {
          return setIsContentOverflow(false);
        }

        const isContentOverflow = scrollComponent.scrollWidth > scrollContainer.scrollWidth;
        setIsContentOverflow(isContentOverflow);
      }
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [itemsLength, expandable]);

  useEffect(() => {
    const scrollListener = (ev: Event) => {
      ev.stopPropagation();

      const { scrollWidth, scrollLeft, clientWidth } = ev.currentTarget as HTMLDivElement;
      if (Math.abs(scrollWidth - clientWidth - scrollLeft) < 1) {
        setItemsCountToRender((prev) => Math.min(prev + 10, itemsCountRef.current));
      }
    };

    scrollComponentRef.current?.addEventListener("scroll", scrollListener);
    return () => scrollComponentRef.current?.removeEventListener("scroll", scrollListener);
  }, []);

  const itemsToRender = expanded ? items : items?.slice(0, itemsCountToRender);

  return (
    <div className={clsx("games-container", className)}>
      <div className="flex items-center justify-between">
        <div className="lg:text-xl flex items-center gap-1.5 text-science-blue font-bold text-xs uppercase dark:text-metallic">
          {hasIcon && <CategoryIcon id={categoryId} />}
          <span className="leading-none">{label}</span>
        </div>

        <div
          className={clsx(
            "flex items-center gap-5",
            (!expandable || !isContentOverflow) && "invisible",
          )}
        >
          <Pagination className="mobile-only:hidden" componentRef={scrollComponentRef.current} />
          <Button type="secondary" onClick={() => navigateToCategory(categoryId)}>
            {$t({ defaultMessage: "Show all" })}
          </Button>
        </div>
      </div>

      <div className="lg:mt-5 mt-3" ref={scrollContainerRef}>
        <div
          className={`game-list peer ${expanded ? "game-list_expanded" : "game-list_collapsed"}`}
          ref={scrollComponentRef}
        >
          {itemsToRender
            ? itemsToRender.map(({ id, pic, providerName, name, stringId, isFavourite }, idx) => (
                <GameCard
                  key={idx}
                  imgSrc={pic}
                  title={name}
                  provider={providerName}
                  id={id}
                  gameStringId={stringId}
                  isFavourite={isFavourite}
                />
              ))
            : range(18)((idx) => <GameCard.Skeleton key={idx} />)}
        </div>
        <div className="lg:text-xl text-xs hidden peer-empty:block">
          {$t({ defaultMessage: "No games in this category" })}
        </div>
      </div>
    </div>
  );
});
