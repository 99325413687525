import { clsx } from "clsx";

import { CategoryIcon } from "@features/category/category-icon";

import { useNavigateToCategory } from "../lib/use-navigate-to-category";

interface ICategoryButtonProps {
  className?: string;
  children: React.ReactNode;
  isActive: boolean;
  disabled?: boolean;
  id: string | number;
}

export function CategoryButton({
  className,
  children,
  isActive,
  disabled,
  id,
}: ICategoryButtonProps) {
  const navigate = useNavigateToCategory();

  return (
    <button
      className={clsx(
        `${isActive ? "category-btn category-btn_active" : "category-btn"}`,
        className,
      )}
      type="button"
      disabled={disabled}
      onClick={() => navigate(id)}
    >
      <CategoryIcon id={id} />
      <span className="truncate leading-none dark:text-metallic">{children}</span>
    </button>
  );
}
