import { clsx } from "clsx";

import bonusGiftBoxImg from "@assets/bonus_gift_box_preview_2x.png";
import { TakeBonusShape, TakeBonusText } from "./components";

interface ITakeBonusButtonProps {
  className?: string;
  type: string;
  amount: string;
  onClick: VoidFunction;
}

export function TakeBonusButtonView({ className, type, amount, onClick }: ITakeBonusButtonProps) {
  return (
    <TakeBonusShape
      className={clsx(
        className,
        "h-[7.25em] cursor-pointer lg:hover:[box-shadow:0_0_1.125em_#fff3a6]",
      )}
      onClick={onClick}
    >
      <img
        className="object-contain w-[7em] h-[9em]"
        src={bonusGiftBoxImg}
        alt="bonus gift box"
        width="100%"
        height="100%"
      />
      <div className="text-left ml-[0.75em]">
        <TakeBonusText className="text-[1.5em]">{type}</TakeBonusText>
        <TakeBonusText className="text-[1.875em] mt-[0.125em]">{amount}</TakeBonusText>
      </div>
    </TakeBonusShape>
  );
}
