import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";
import { forwardRef, useState, type ForwardedRef } from "react";

interface IInputSearchProps {
  className?: string;
  label?: string;
  onChange?: (value: string) => void;
}

export const InputSearch = forwardRef(function InputSearch(
  {
    className,
    label,
    onChange,
    ...inputProps
  }: IInputSearchProps & Omit<React.ComponentProps<"input">, "onChange">,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  const { $t } = useTranslate();
  const [text, setText] = useState("");

  function handleClear() {
    handleChange("");
  }

  function handleInput(ev: React.ChangeEvent<HTMLInputElement>) {
    handleChange(ev.target.value);
  }

  function handleChange(value: string) {
    value = value.toLowerCase();

    setText(value);

    if (onChange) {
      onChange(value);
    }
  }

  return (
    <div className={clsx("input-search", className)}>
      <label className="flex items-center">
        {label && (
          <span className="mr-2.5 font-bold">
            {label}
            {skipIntl(":")}
          </span>
        )}
        <div className="grow relative">
          <input
            ref={ref}
            className="input-search__input peer"
            type="text"
            placeholder={$t({ defaultMessage: "Search" })}
            {...inputProps}
            value={text}
            onChange={handleInput}
          />
          <button
            className="lg:size-4 absolute top-1/2 left-2.5 -translate-y-1/2 size-3"
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
              <path
                className="fill-bright-gray dark:[fill:url(#linearGradientMetallic)]"
                d="M10.09 4.02a4.36 4.36 0 1 1-6.18 6.17 4.36 4.36 0 0 1 6.18-6.17Zm2.06 6.82a6.37 6.37 0 1 0-1.41 1.42l3.14 3.14a1 1 0 0 0 1.41-1.42l-3.14-3.14Z"
              />
            </svg>
          </button>
          <button
            className="absolute top-1/2 right-2.5 -translate-y-1/2"
            type="button"
            onClick={handleClear}
            hidden={!text}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path
                className="fill-bright-gray dark:[fill:url(#linearGradientMetallic)]"
                d="m7.51 6 4.25-4.24L10.34.34 6.1 4.6 1.86.34.44 1.76 4.7 6 .44 10.24l1.42 1.42L6.1 7.4l4.24 4.25 1.42-1.42L7.5 6Z"
              />
            </svg>
          </button>
          <div className="text-xs text-radical-red font-light absolute left-2.5 invisible peer-invalid:visible">
            {$t({ defaultMessage: "Required" })}
          </div>
        </div>
      </label>
    </div>
  );
});
