import GameBall from "@assets/ball.png";
import MiniGameBg from "@assets/mini-game-bg.jpg";
import { useGSAP } from "@gsap/react";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import gsap from "gsap";
import { useRef } from "react";

import { JackpotMap } from "@entities/jackpot";
import { usePlayerMoneyFormatter } from "@entities/player";
import { CongratsModal } from "@features/congrats-modal";
import { ErrorModal } from "@features/error-modal";
import { APIError, emitter, useMobile, useMobileLandscape } from "@shared/lib";
import { Button } from "@shared/ui/button";

import { useMiniGameContext } from "../model/use-mini-game-context";

import type { TJackpotsType } from "@entities/jackpot";

const winCoordinateMapDesktop: Record<TJackpotsType, { x: string; y: string }> = {
  mini: { x: "-=100", y: "-=300" },
  major: { x: "-=100", y: "-=400" },
  grand: { x: "+=100", y: "-=300" },
  ultimate: { x: "+=100", y: "-=400" },
};

const winCoordinateMapMobile: Record<TJackpotsType, { x: string; y: string }> = {
  mini: { x: "-=100", y: "-=150" },
  major: { x: "-=100", y: "-=250" },
  grand: { x: "+=100", y: "-=150" },
  ultimate: { x: "+=100", y: "-=250" },
};

const winCoordinateMapMobileLandscape: Record<TJackpotsType, { x: string; y: string }> = {
  mini: { x: "-=80", y: "-=80" },
  major: { x: "-=80", y: "-=140" },
  grand: { x: "+=80", y: "-=80" },
  ultimate: { x: "+=80", y: "-=140" },
};

const altBall = "game-ball";
gsap.registerPlugin(useGSAP);

export const MiniGame = () => {
  const { $t } = useTranslate();
  const isMobile = useMobile();
  const isMobileLandscape = useMobileLandscape();
  const { win, fetchWinAccept, reelStatus, updateReelStatus, error } = useMiniGameContext();
  const ballRef = useRef<HTMLImageElement | null>(null);
  const { contextSafe } = useGSAP({ scope: ballRef });
  const formatMoney = usePlayerMoneyFormatter();

  const computedWinCoords = isMobileLandscape
    ? winCoordinateMapMobileLandscape
    : isMobile
      ? winCoordinateMapMobile
      : winCoordinateMapDesktop;

  if (!win) return null;

  const slotName = JackpotMap[win.slot] as TJackpotsType;
  const winMessage = $t(
    { defaultMessage: `you won {slotName} jackpot` },
    {
      slotName,
    },
  );

  const onTakeWin = () => {
    fetchWinAccept();
    updateReelStatus("spin-stop");
  };

  function openCongratsModal() {
    if (!win) return;

    emitter.emit("CONGRATS_MODAL_OPEN", {
      message: winMessage,
      value: `${formatMoney(win.value)}`,
      onTake: onTakeWin,
    });
  }

  const onEnter = contextSafe(() => {
    updateReelStatus("spin-start");

    gsap.to(ballRef.current, {
      ...computedWinCoords[slotName],
      scale: "-= 0.3",
      duration: 0.8,
      ease: "linear",
      rotation: 270,
    });

    gsap.to(ballRef.current, {
      scale: 0.6,
      duration: 1,
      ease: "bounce.out",
      onComplete: openCongratsModal,
    });
  });

  if (error) {
    emitter.emit("ERROR_MODAL_OPEN", new APIError(error));
  }

  return (
    <>
      <CongratsModal />
      <ErrorModal />

      <div
        className="
        fixed left-0 right-0 top-0 bottom-0 z-[101]
        h-full p-5
        mobile-only:bg-athens-gray dark:bg-outer-space
       mobile-only:p-2"
      >
        <div
          className="relative
           h-full w-full bg-cover
            mobile-only:h-3/4
            mobile-only:top-1.5
            mobile-only:landscape:h-full
             bg-no-repeat bg-center bg-mercury border-1 border-keppel rounded-sm"
          style={{ backgroundImage: `url(${MiniGameBg})` }}
        >
          <div
            className="
           w-44 absolute left-1/3 top-1/2 mt-18
           mobile-only:w-20
           mobile-only:left-12
           mobile-only:scale-150
           mobile-only:landscape:left-1/3
           mobile-only:landscape:mt-12
          "
          >
            {/* <JackpotTicker type="mini" flip /> */}
          </div>
          <div className="w-44 absolute left-1/3 top-1/2 -mt-10 mobile-only:w-20 mobile-only:left-12 mobile-only:scale-150 mobile-only:pt-2  mobile-only:landscape:left-1/3">
            {/* <JackpotTicker type="major" flip /> */}
          </div>
          <div
            className="
            w-44 absolute right-1/3 top-1/2 mt-18
            mobile-only:w-20
            mobile-only:right-12
            mobile-only:scale-150
            mobile-only:landscape:right-1/3
            mobile-only:landscape:mt-12
          "
          >
            {/* <JackpotTicker type="grand" /> */}
          </div>
          <div
            className="w-44
          absolute right-1/3 top-1/2 pr-25  -mt-10 mobile-only:w-20 mobile-only:right-12 mobile-only:scale-150 mobile-only:pt-2
            mobile-only:landscape:right-1/3"
          >
            {/* <JackpotTicker type="ultimate" /> */}
          </div>
          <Button
            disabled={reelStatus !== "spin-stop"}
            onClick={onEnter}
            type="primary"
            className=" w-20 absolute bottom-36 left-0 right-0 m-auto mobile-only:bottom-16"
          >
            {$t({ defaultMessage: "Strike" })}
            {skipIntl("!")}
          </Button>
          <img
            style={{ perspective: "1000px" }}
            ref={ballRef}
            className="absolute bottom-8 left-0 right-0 m-auto w-24  mobile-only:scale-50 mobile-only:-bottom-4"
            src={GameBall}
            alt={altBall}
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};
