import { useTranslate } from "@lobby/ocb-intl";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useEffect, useMemo, useRef, useState } from "react";

import fortuneWheelPinImg from "@app/assets/fortune-wheel-pin.png";
import { FortuneWheel as FortuneWheelContext } from "@entities/fortune-wheel";
import { APIError, emitter, useFortuneWheelCountdown } from "@shared/lib";

import {
  buildSectorPath,
  calcAcceleration,
  calcCircumference,
  calcDistance,
  calcPointX,
  calcPointY,
  calcSectorAngle,
  calcTotalRotation,
  getSectorColors,
  getSectorFilling,
  getWinSectorIndex,
  WHEEL_INNER_RADIUS as r,
  WHEEL_RADIUS as R,
  WHEEL_DIAMETER,
  WHEEL_INITIAL_VELOCITY,
} from "../lib";

import {
  Arrow,
  ArrowBase,
  OuterBorder,
  Pins,
  Timer,
  WheelSector,
  WheelSectorText,
} from "./components";

import type { ApiClientResponse } from "@lobby/api-client";
import type { TSector, TSectorParams, TWheelState, TWinSector } from "../lib";

interface IFortuneWheelProps {
  className?: string;
  sectors: TSector[];
  onWinAnimationEnd?: (params: TSectorParams) => void;
}

export const FortuneWheel = memo(function FortuneWheel({
  className,
  sectors,
  onWinAnimationEnd,
}: IFortuneWheelProps) {
  const { $t } = useTranslate();
  const [wheelState, setWheelState] = useState<TWheelState>("idle");
  const [winnerSector, setWinnerSector] = useState<TWinSector>();
  const wheelRef = useRef<SVGGElement>(null);

  const queryClient = useQueryClient();
  const { mutate: spinWheel } = FortuneWheelContext.useWheelSpin();
  const { data } = FortuneWheelContext.useWheelState();

  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);

  const winSectorId = winnerSector?.sectorId;
  const winSectorIndex = useMemo(
    () => getWinSectorIndex(sectors, winSectorId),
    [sectors, winSectorId],
  );

  const sectorAngle = calcSectorAngle(sectors.length);
  const sectorPath = buildSectorPath(R, r, sectorAngle);
  const sectorTextX = calcPointX(R, r - 60, -90 - 1.5);

  const spinText = $t({ defaultMessage: "SPIN" }).toUpperCase();

  function invalidateWheelState() {
    queryClient.invalidateQueries({ queryKey: ["Lobby.getCurrent"] });
  }

  function handleSpinMutationSuccess({ result, error }: ApiClientResponse<"FortuneWheel.getWin">) {
    if (result) {
      setWheelState("spinning");
      setWinnerSector(result);
    }

    if (error) {
      setWheelState("ready");
      invalidateWheelState();

      const apiError = new APIError(error.message, {
        code: error.code,
      });

      emitter.emit("ERROR_MODAL_OPEN", apiError);
    }
  }

  function handleSpinClick() {
    setWheelState("fetching");
    spinWheel(undefined, { onSuccess: handleSpinMutationSuccess });
  }

  useEffect(() => {
    if (wheelState === "win" && winnerSector && onWinAnimationEnd) {
      setTimeout(
        () =>
          onWinAnimationEnd({
            id: winnerSector.sectorId,
            value: winnerSector.win,
            colors: getSectorColors(winSectorIndex),
          }),
        2000,
      );
    }
  }, [wheelState, winSectorIndex, winnerSector]);

  useEffect(() => {
    if (wheelState !== "spinning") {
      return;
    }

    const wheel = wheelRef.current!;
    const circumference = calcCircumference(R);
    const totalRotation = calcTotalRotation(sectors.length, winSectorIndex);
    const totalDistance = (totalRotation / 360) * circumference;
    const initialVelocity = WHEEL_INITIAL_VELOCITY;
    const acceleration = calcAcceleration(initialVelocity, totalDistance);

    let lastDistance = 0;
    const startTime = performance.now();

    function animate(time: DOMHighResTimeStamp) {
      const elapsed = time - startTime;

      const distance = calcDistance(initialVelocity, elapsed, acceleration);
      if (lastDistance > distance) {
        setWheelState("win");
        return;
      }
      lastDistance = distance;

      const angle = (distance / circumference) * 360;

      wheel.setAttribute("transform", `rotate(${angle} ${R} ${R})`);

      requestAnimationFrame(animate);
    }

    const requestId = requestAnimationFrame(animate);

    () => cancelAnimationFrame(requestId);
  }, [wheelState]);

  useEffect(() => {
    setWheelState(countdown > 0 ? "idle" : "ready");
  }, [countdown]);

  return (
    <div className={className}>
      <svg
        className="select-none *:pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="100%"
        height="100%"
        viewBox={`0 0 ${WHEEL_DIAMETER} ${WHEEL_DIAMETER}`}
        textRendering="optimizeSpeed"
        colorRendering="optimizeSpeed"
      >
        <ArrowBase />

        {/* Sectors group */}
        <g ref={wheelRef}>
          {sectors.map(({ id }, idx) => (
            <WheelSector
              key={idx}
              path={sectorPath}
              fill={getSectorFilling(idx)}
              transform={`rotate(${idx * sectorAngle} ${R} ${R})`}
              isWinSector={id === winSectorId && wheelState === "win"}
            />
          ))}

          {sectors.map(({ value }, idx) => (
            <g transform={`rotate(${idx * sectorAngle} ${R} ${R})`} key={idx}>
              {(value / 100 + "000")
                .toString()
                .split("")
                .map((char, idx) => (
                  <WheelSectorText
                    x={sectorTextX}
                    y={calcPointY(R, r - 60 - idx * 35, -100)}
                    key={idx}
                  >
                    {char}
                  </WheelSectorText>
                ))}
            </g>
          ))}
        </g>

        <OuterBorder />

        <g>
          <circle cx="50%" cy="50%" fill="black" filter="url(#shadowFilter)" r="16.2%" />
          <path
            fill="url(#paint100_linear_320_2707)"
            d="M385 504c65.72 0 119-53.28 119-119s-53.28-119-119-119-119 53.28-119 119 53.28 119 119 119Z"
          />
          <circle fill="red" cx="50%" cy="50%" r="10%" />

          {wheelState === "ready" ? (
            <circle
              className="peer priority:pointer-events-auto cursor-pointer"
              fill="url(#paint102_radial_320_2707)"
              cx="50%"
              cy="50%"
              r="10%"
              fillOpacity="0.7"
              onClick={handleSpinClick}
            />
          ) : (
            <circle
              fill="url(#paint102_radial_320_2707)"
              cx="50%"
              cy="50%"
              r="10%"
              fillOpacity="0.7"
            />
          )}

          <circle
            className="peer-hover:invisible"
            fill="url(#paint102_radial_320_2707)"
            cx="50%"
            cy="50%"
            r="10%"
            fillOpacity="0.7"
          />
          <circle
            className="peer-active:visible invisible"
            fill="url(#spinButtonActiveStateShadowRadial)"
            cx="50%"
            cy="50%"
            r="10%"
            fillOpacity="1"
          />

          {(wheelState === "ready" || wheelState === "fetching") && (
            <text
              className="peer-active:text-[50px] text-[55px]"
              x={R}
              y={R}
              dy="5"
              alignmentBaseline="middle"
              textAnchor="middle"
              fontFamily="Oswald"
              fill="white"
              opacity="0.9"
            >
              {spinText}
            </text>
          )}

          {wheelState === "idle" && <Timer />}

          <path
            fill="url(#paint103_linear_320_2707)"
            fillRule="evenodd"
            d="M385 509c68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124Zm0-11.81c61.96 0 112.2-50.23 112.2-112.2 0-61.95-50.24-112.18-112.2-112.18S272.81 323.04 272.81 385 323.04 497.19 385 497.19Z"
          />
          <path
            fill="#c00"
            fillOpacity="0.7"
            d="M353.24 347.6a24.16 24.16 0 0 0-7.07 13.16l-27.82 2.31a59.39 59.39 0 0 1 17.74-33.02l17.15 17.54ZM368.52 341.14c-5.1.25-9.8 2.03-13.97 5.3l-15.16-19.29a59.4 59.4 0 0 1 35.05-13.29l-5.92 27.28Z"
            style={{
              mixBlendMode: "overlay",
            }}
          />
          <path
            stroke="url(#paint105_linear_320_2707)"
            strokeMiterlimit="10"
            d="M385.1 503.19c65.22 0 118.09-52.87 118.09-118.1 0-65.22-52.87-118.09-118.1-118.09C319.88 267 267 319.87 267 385.1c0 65.22 52.87 118.09 118.1 118.09Z"
            style={{
              mixBlendMode: "multiply",
            }}
          />
          <path
            stroke="url(#paint107_linear_320_2707)"
            strokeMiterlimit="10"
            d="M385 509.5c68.76 0 124.5-55.74 124.5-124.5 0-68.76-55.74-124.5-124.5-124.5-68.76 0-124.5 55.74-124.5 124.5 0 68.76 55.74 124.5 124.5 124.5Z"
          />
          <circle
            cx="50%"
            cy="50%"
            r="112.5"
            fill="url(#paint108_radial_320_2707)"
            fillOpacity=".7"
            style={{
              mixBlendMode: "multiply",
            }}
          />
          <circle
            cx="50%"
            cy="50%"
            r="112.5"
            fill="url(#paint109_radial_320_2707)"
            fillOpacity=".7"
            transform="rotate(90 384.5 385.5)"
            style={{
              mixBlendMode: "multiply",
            }}
          />
          <path
            stroke="#EDEBE2"
            strokeDasharray="3 34"
            strokeWidth="20"
            d="M417.61 492a111.37 111.37 0 0 1-31.68 5l-.13-10a112.7 112.7 0 0 1-2.6 0l-.13 10a111.37 111.37 0 0 1-31.68-5l2.97-9.55c-.83-.25-1.65-.52-2.47-.8l-3.2 9.47a111.14 111.14 0 0 1-28.58-14.58l5.78-8.16c-.7-.5-1.4-1-2.09-1.52l-5.98 8.01a112.17 112.17 0 0 1-22.69-22.69l8.01-5.98c-.52-.7-1.02-1.39-1.52-2.1l-8.16 5.79a111.14 111.14 0 0 1-14.58-28.57l9.47-3.21c-.28-.82-.55-1.64-.8-2.47l-9.55 2.97a111.37 111.37 0 0 1-5-31.68l10-.13a112.7 112.7 0 0 1 0-2.6l-10-.13a111.37 111.37 0 0 1 5-31.68l9.55 2.97c.25-.83.52-1.65.8-2.47l-9.47-3.2a111.14 111.14 0 0 1 14.58-28.58l8.16 5.78c.5-.7 1-1.4 1.52-2.09l-8.01-5.98a112.17 112.17 0 0 1 22.69-22.69l5.98 8.01c.7-.52 1.39-1.02 2.1-1.52l-5.79-8.16c8.69-6.16 18.3-11.1 28.57-14.58l3.21 9.47c.82-.28 1.64-.55 2.47-.8l-2.97-9.55a111.37 111.37 0 0 1 31.68-5l.13 10a112.7 112.7 0 0 1 2.6 0l.13-10c11.02.15 21.65 1.88 31.68 5l-2.97 9.55c.83.25 1.65.52 2.47.8l3.2-9.47a111.14 111.14 0 0 1 28.58 14.58l-5.78 8.16c.7.5 1.4 1 2.09 1.52l5.98-8.01a112.17 112.17 0 0 1 22.69 22.69l-8.01 5.98c.52.7 1.02 1.39 1.52 2.1l8.16-5.79c6.16 8.69 11.1 18.3 14.58 28.57l-9.47 3.21c.28.82.55 1.64.8 2.47l9.55-2.97a111.37 111.37 0 0 1 5 31.68l-10 .13a112.7 112.7 0 0 1 0 2.6l10 .13a111.37 111.37 0 0 1-5 31.68l-9.55-2.97c-.25.83-.52 1.65-.8 2.47l9.47 3.2a111.14 111.14 0 0 1-14.58 28.58l-8.16-5.78c-.5.7-1 1.4-1.52 2.09l8.01 5.98a112.17 112.17 0 0 1-22.69 22.69l-5.98-8.01c-.7.52-1.39 1.02-2.1 1.52l5.79 8.16a111.1 111.1 0 0 1-28.57 14.58l-3.21-9.47c-.82.28-1.64.55-2.47.8l2.97 9.55Z"
            style={{
              mixBlendMode: "color-burn",
            }}
          />
        </g>

        <Pins />
        <Arrow />

        <defs>
          <image id="fortune-wheel-pin" href={fortuneWheelPinImg} height="20" width="20" />

          <linearGradient id="paint72_linear_320_2707" gradientTransform="rotate(0)">
            <stop stopColor="#b8b8b8" />
            <stop offset=".15" stopColor="#888" />
            <stop offset=".46" stopColor="#eaeaea" />
            <stop offset=".61" stopColor="#f2f2f2" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".84" stopColor="#f4f1e7" />
            <stop offset="1" stopColor="#868686" />
            <animateTransform
              attributeName="gradientTransform"
              type="rotate"
              from="0"
              to="360"
              dur="60s"
              repeatCount="indefinite"
            />
          </linearGradient>
          <linearGradient id="paint73_linear_320_2707">
            <stop stopColor="#fffdef" />
            <stop offset=".08" stopColor="#fffae9" />
            <stop offset=".16" stopColor="#eae5d7" />
            <stop offset=".25" stopColor="#dbd7cd" />
            <stop offset=".33" stopColor="#dadada" />
            <stop offset=".41" stopColor="#ccc6ba" />
            <stop offset=".49" stopColor="#bebdba" />
            <stop offset=".56" stopColor="#bcbcbc" />
            <stop offset=".63" stopColor="#c5c3bc" />
            <stop offset=".71" stopColor="#bbb8b2" />
            <stop offset=".78" stopColor="#c5c5c5" />
            <stop offset=".86" stopColor="#f1eddf" />
            <stop offset="1" stopColor="#fdfdfc" />
          </linearGradient>
          <linearGradient id="paint74_linear_320_2707" gradientTransform="rotate(0)">
            <stop stopColor="#6b6b6b" />
            <stop offset=".24" stopColor="#faf7e9" />
            <stop offset=".32" stopColor="#e6e4dc" />
            <stop offset=".4" stopColor="#dfd8c8" />
            <stop offset=".47" stopColor="#c7c3bb" />
            <stop offset=".51" stopColor="#cdcac5" />
            <stop offset=".55" stopColor="#d6caaf" />
            <stop offset=".59" stopColor="#d5cdbb" />
            <stop offset=".64" stopColor="#e2ded4" />
            <stop offset=".68" stopColor="#eae5d5" />
            <stop offset=".72" stopColor="#f4f2e7" />
            <stop offset=".77" stopColor="#fefbea" />
            <stop offset="1" stopColor="#626262" />

            <animateTransform
              attributeName="gradientTransform"
              type="rotate"
              from="0"
              to="360"
              dur="30s"
              repeatCount="indefinite"
            />
          </linearGradient>
          <linearGradient
            id="outerRingLinGrad"
            x1="659.8"
            x2="110.19"
            y1="659.8"
            y2="110.19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>

          <linearGradient
            id="paint100_linear_320_2707"
            x1="470"
            x2="300"
            y1="470"
            y2="300"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5d5d5d" />
            <stop offset=".24" stopColor="#fdfbef" />
            <stop offset=".32" stopColor="#e6e2d3" />
            <stop offset=".4" stopColor="#ded9cc" />
            <stop offset=".47" stopColor="#d1cec8" />
            <stop offset=".51" stopColor="#c7c1b7" />
            <stop offset=".55" stopColor="#cdcdcd" />
            <stop offset=".59" stopColor="#d2d2d2" />
            <stop offset=".64" stopColor="#dcdcdc" />
            <stop offset=".68" stopColor="#eae7df" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#ededed" />
            <stop offset=".9" stopColor="#8e8e8e" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint101_linear_320_2707"
            x1="425.39"
            x2="341.38"
            y1="427.39"
            y2="343.38"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5d5d5d" />
            <stop offset=".24" stopColor="#fdfbef" />
            <stop offset=".32" stopColor="#e6e2d3" />
            <stop offset=".4" stopColor="#ded9cc" />
            <stop offset=".47" stopColor="#d1cec8" />
            <stop offset=".51" stopColor="#c7c1b7" />
            <stop offset=".55" stopColor="#cdcdcd" />
            <stop offset=".59" stopColor="#d2d2d2" />
            <stop offset=".64" stopColor="#dcdcdc" />
            <stop offset=".68" stopColor="#eae7df" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#ededed" />
            <stop offset=".9" stopColor="#8e8e8e" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint103_linear_320_2707"
            x1="474.2"
            x2="295.8"
            y1="474.2"
            y2="295.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint104_linear_320_2707"
            x1="474.2"
            x2="295.8"
            y1="474.2"
            y2="295.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint105_linear_320_2707"
            x1="470.05"
            x2="300.14"
            y1="470.05"
            y2="300.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint106_linear_320_2707"
            x1="465.9"
            x2="304.48"
            y1="465.9"
            y2="304.48"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <linearGradient
            id="paint107_linear_320_2707"
            x1="474.2"
            x2="295.8"
            y1="474.2"
            y2="295.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <radialGradient id="paint102_radial_320_2707">
            <stop stopColor="red" />
            <stop offset=".1" stopColor="#F90000" />
            <stop offset=".23" stopColor="#E80000" />
            <stop offset=".38" stopColor="#CD0000" />
            <stop offset=".39" stopColor="#C00" />
            <stop offset=".66" stopColor="#900" />
            <stop offset=".81" stopColor="#600" />
            <stop offset="1" />
          </radialGradient>
          <linearGradient id="linGrad003" gradientTransform="rotate(90)">
            <stop stopColor="#5D5D5D" />
            <stop offset=".24" stopColor="#FDFBEF" />
            <stop offset=".32" stopColor="#E6E2D3" />
            <stop offset=".4" stopColor="#DED9CC" />
            <stop offset=".47" stopColor="#D1CEC8" />
            <stop offset=".51" stopColor="#C7C1B7" />
            <stop offset=".55" stopColor="#CDCDCD" />
            <stop offset=".59" stopColor="#D2D2D2" />
            <stop offset=".64" stopColor="#DCDCDC" />
            <stop offset=".68" stopColor="#EAE7DF" />
            <stop offset=".72" stopColor="#fff" />
            <stop offset=".77" stopColor="#EDEDED" />
            <stop offset=".9" stopColor="#8E8E8E" />
            <stop offset="1" stopColor="#727272" />
          </linearGradient>
          <radialGradient id="radGrad000" cx="0.85" cy="0.05" r="1">
            <stop stopColor="#f9df7b" />
            <stop offset=".4" stopColor="#ff002e" />
            <stop offset="1" stopColor="#000" />
          </radialGradient>

          <radialGradient id="paint108_radial_320_2707">
            <stop offset=".83" stopColor="#fff" />
            <stop offset="1" stopColor="#b9b9b9" />
          </radialGradient>
          <radialGradient id="paint109_radial_320_2707">
            <stop offset=".83" stopColor="#fff" />
            <stop offset="1" stopColor="#b7b7b7" />
          </radialGradient>
          <radialGradient id="sectorsShadowRadial">
            <stop offset=".83" stopColor="transparent" />
            <stop offset="1" stopColor="#2d2d2d" />
          </radialGradient>
          <radialGradient id="spinButtonActiveStateShadowRadial">
            <stop stopColor="transparent" />
            <stop offset="0.75" stopColor="transparent" />
            {/* <stop offset="0.8" stopColor="#111" /> */}
            <stop offset="1" stopColor="#111" />
          </radialGradient>

          <filter id="shadowFilter">
            <feDropShadow dx="0" dy="0" stdDeviation="8" />
          </filter>
          <filter id="arrowShadowFilter">
            <feDropShadow dx="0" dy="5" stdDeviation="3" />
          </filter>
        </defs>
      </svg>
    </div>
  );
});
